body {
  margin: 0;
}

@font-face {
  font-family: "Gotham Black";
  src: local("Gotham Black"),
    url(./assets/fonts/Gotham-Black.otf) format("truetype");
}

@font-face {
  font-family: "Gotham BlackItalic";
  src: local("Gotham BlackItalic"),
    url(./assets/fonts/Gotham-BlackItalic.otf) format("truetype");
}

@font-face {
  font-family: "Gotham Bold";
  src: local("Gotham Bold"),
    url(./assets/fonts/Gotham-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Gotham BoldItalic";
  src: local("Gotham BoldItalic"),
    url(./assets/fonts/Gotham-BoldItalic.otf) format("truetype");
}

@font-face {
  font-family: "Gotham Book";
  src: local("Gotham Book"),
    url(./assets/fonts/Gotham-Book.otf) format("truetype");
}

@font-face {
  font-family: "Gotham BookItalic";
  src: local("Gotham BookItalic"),
    url(./assets/fonts/Gotham-BookItalic.otf) format("truetype");
}

@font-face {
  font-family: "Gotham Light";
  src: local("Gotham Light"),
    url(./assets/fonts/Gotham-Light.otf) format("truetype");
}

@font-face {
  font-family: "Gotham LightItalic";
  src: local("Gotham LightItalic"),
    url(./assets/fonts/Gotham-LightItalic.otf) format("truetype");
}

@font-face {
  font-family: "Gotham Medium";
  src: local("Gotham Medium"),
    url(./assets/fonts/Gotham-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Gotham MediumItalic";
  src: local("Gotham MediumItalic"),
    url(./assets/fonts/Gotham-MediumItalic.otf) format("truetype");
}
