.workshopHeader {
  height: 10vh;
  background-image: linear-gradient(to right, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.workshopTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.nextrastroContainer {
  position: absolute;
  right: 0;
  margin-top: 8.25%;
}

.nextrastro {
  width: 69px;
  height: 55px;
}

.workshopEvent {
  background-color: #f5f5f5;
  margin: 5% 5% 0 5%;
  border-radius: 10px;
  font-family: "Gotham Medium";
  color: #393939;
  padding: 5px 0 5px 10px;
}

.downIcon {
  color: #008ace;
}

.attachment {
  color: #008ace;
  font-family: "Gotham Light";
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.noteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noteTaker {
  margin-top: 5%;
  width: 380px;
  text-align: justify;
  height: 450px;
  border: none;
  font-family: "Gotham Book";
  font-size: 16px;
  padding: 10px;
  resize: none;
}

.saveContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.saveButton {
  font-family: "Gotham Medium";
  font-size: 12px;
  line-height: 25px;
  background-image: linear-gradient(to right, #008ace, #78deff);
  color: #ffffff;
  height: 25px;
  width: 40%;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  border: none;
}
