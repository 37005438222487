p {
  font-family: "Gotham Bold";
}

.agendaHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.agendaTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.nextrastroContainer {
  position: absolute;
  right: 0;
  margin-top: 8.5%;
}

.nextrastro {
  width: 69px;
  height: 55px;
}

.event {
  margin-top: 10%;
}

.eventTitle {
  font-family: "Gotham Bold";
  margin-top: 10%;
  padding-left: 10%;
}

.eventContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  background-color: #f5f5f5;
  height: 15%;
  width: 90%;
  margin-left: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 20px;
}

.eventLeft {
  display: flex;
  flex-direction: column;
  padding-left: 12%;
  font-family: "Gotham Medium";
}

.eventDate {
  font-family: "Gotham Light";
}

.eventRight {
  font-family: "Gotham Medium";
  padding-right: 10%;
}

.startTime {
  color: black;
}

.endTime {
  color: #555555;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: "Gotham Light";
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
