.rocketProgramHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.rocketProgramTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.nextrastroContainer {
  position: absolute;
  right: 0;
  margin-top: 8.5%;
}

.nextrastro {
  width: 69px;
  height: 55px;
}

.rocketProgramSubtitle {
  font-family: "Gotham Bold";
  font-size: 16px;
  color: #747474;
  padding: 5% 0 0 5%;
}

.linkThread {
  position: absolute;
  right: 0;
  top: 115px;
  background-color: #0f4c81;
  width: 111px;
  height: 33px;
  font-size: 12px;
  font-family: "Gotham Book";
  color: white;
  border-radius: 10px 0 0 10px;
  line-height: 33px;
  padding-left: 15px;
  cursor: pointer;
}

.members {
  font-family: "Gotham Book";
  font-size: 14px;
  cursor: pointer;
}

.rpEvent {
  background-color: #f5f5f5;
  margin: 5% 5% 0 5%;
  border-radius: 10px;
  font-family: "Gotham Medium";
  color: #393939;
  padding: 5px 0 5px 10px;
}

.rpEventnext {
  background-color: #f5f5f5;
  margin: 5% 5% 0 5%;
  border-radius: 10px;
  font-family: "Gotham Medium";
  color: #969696;
  padding: 5px 0 5px 10px;
}
