.threadHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.threadTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.nextrastroContainer {
  position: absolute;
  right: 0;
  margin-top: 8.25%;
}

.nextrastro {
  width: 69px;
  height: 55px;
}

.threadMessages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  height: 80%;
}

.threadMessage {
  display: flex;
  flex-direction: row;
  width: 95%;
  height: 200px;
  box-shadow: 2px 2px 5px 3px #777;
  font-family: "Gotham Book";
  margin-top: 2%;
  margin-bottom: 2%;
}

.messageLeft {
  height: 100%;
  width: 2%;
  background-color: #008ace;
}

.messageContent {
  text-align: justify;
  display: flex;
  flex-direction: column;
}

.messageAnswer {
  margin-top: 2%;
  font-family: "Gotham Book";
  font-style: italic;
  color: #808080;
}

.messageHeader {
  display: flex;
  flex-direction: row;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
}

.messageUsername {
  font-family: "Gotham Bold";
  font-size: 14px;
  color: #393939;
}

.messageDate {
  font-family: "Gotham Bold";
  font-size: 12px;
  color: #808080;
}

.messageContent {
  text-align: justify;
  padding: 5% 2% 0 2%;
}

.answerContainer {
  border-top: 2px solid #d8d8d8;
  color: #1682fd;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5%;
  width: 360px;
}

.plusIcon {
  position: fixed;
  right: 2%;
  bottom: 10%;
  cursor: pointer;
  z-index: 3;
  color: #0f4c81;
}

.formButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.newMessage {
  position: fixed;
  top: 200px;
  left: 100px;
  box-shadow: 5px 5px 15px 5px #393939;
  border: 2px solid #393939;
  border-radius: 10px;
  width: 200px;
  height: 400px;
  background-color: white;
}

.inputMessage {
  margin: 5% 5%;
  width: 150px;
  height: 300px;
  text-align: justify;
  font-family: "Gotham Book";
  font-size: 16px;
  padding: 10px;
  resize: none;
}

.closeButton {
  font-family: "Gotham Medium";
  font-size: 12px;
  line-height: 25px;
  background: #fc686f;
  color: #ffffff;
  height: 25px;
  width: 40%;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  border: none;
}

.openButton {
  font-family: "Gotham Medium";
  font-size: 12px;
  line-height: 25px;
  background: #78deff;
  color: #ffffff;
  height: 25px;
  width: 40%;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  border: none;
}
