.loginBody {
  display: flex;
  flex-direction: column;
}

.loginTitle {
  color: #0f4c81;
  font-family: "Gotham Bold";
  font-size: 55px;
  text-align: left;
  padding-left: 10%;
  margin-top: 10%;
}

.loginSubtitle {
  color: #0f4c81;
  font-family: "Gotham Bold";
  font-size: 25px;
  text-align: left;
  font-style: italic;
  padding-left: 10%;
}

.loginInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.loginInput {
  width: 80%;
  font-family: "Gotham Bold";
  font-size: 20;
  padding-left: 5px;
  color: #393939;
  background-color: #f6f6f6;
  height: 50px;
  border-radius: 15px;
  border: none;
  margin: 10px;
}

.red-text {
  color: red;
  font-family: "Gotham Bold";
  text-align: center;
}

.loginFrgtpwd {
  color: #0f4c81;
  font-family: "Gotham Book";
  font-style: italic;
  font-size: 15px;
  text-align: right;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.loginButton {
  margin: 0 auto;
  border: none;
  background-color: #0f4c81;
  color: white;
  width: 70%;
  height: 50px;
  border-radius: 5px;
  font-family: "Gotham Bold";
  font-size: 30px;
  cursor: pointer;
}

.loginFusee {
  margin: 10% auto;
}
