.moreHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
  padding-left: 5%;
  padding-top: 5%;
}

.moreAvatar {
  position: absolute;
  right: 5%;
  top: 5%;
}

.moreMain {
  font-family: "Gotham Book";
  font-size: 16;
  color: #747474;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.moreTitles {
  margin-left: 5%;
  margin-top: 5%;
}

.moreContainer {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  cursor: pointer;
  text-decoration: none;
}

.moreContainer:visited {
  text-decoration: none;
  color: #747474;
}

.moreFlag {
  color: #f2994a;
}

.moreBell {
  color: #fc686f;
}

.moreBubble {
  color: #0f4c81;
}

.moreSections {
  padding-top: 2%;
  padding-left: 5%;
  cursor: pointer;
}

.moreNP {
  color: #008ace;
}

.moreArticles {
  color: #78deff;
}

.moreSettings {
  color: #747474;
}
