.homeHeader {
  height: 10vh;
  background-image: linear-gradient(to right, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.homeTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.nextrastroContainer {
  position: absolute;
  right: 0;
  transform: scaleX(-1);
}

.nextrastro {
  width: 69px;
  height: 55px;
}

.homeContenttitle {
  font-family: "Gotham Medium";
  font-size: 14px;
  color: #393939;
  padding-left: 5%;
  padding-top: 5%;
}

.progressBarcontainer {
  display: flex;
  flex-direction: row;
  margin: 1% 5% 3% 5%;
}

.progressBarleft {
  background-image: linear-gradient(to right, #008ace, #78deff);
  width: 80%;
  height: 30px;
  font-family: "Gotham Book";
  font-size: 14px;
  text-align: right;
  color: #ffffff;
  line-height: 30px;
  padding-right: 5px;
}

.progressBarright {
  background-color: #f0f0f0;
  width: 20%;
  height: 30px;
}

.homeContentsubtitle {
  font-family: "Gotham Medium";
  font-size: 16px;
  color: #393939;
  padding-left: 5%;
  padding-top: 5%;
}

.homeEvent {
  background-color: #f5f5f5;
  margin: 5% 5% 0 5%;
  border-radius: 10px;
  font-family: "Gotham Medium";
  color: #393939;
  padding: 5px 0 5px 10px;
}
