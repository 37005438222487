.membersHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.membersTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.membersCard {
  width: 90%;
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin: 5% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #393939;
}

.membersName {
  padding: 3% 10px;
  grid-area: 1 / 1 / 2 / 2;
  font-family: "Gotham Bold";
}

.membersCompany {
  padding: 0 10px;
  grid-area: 2 / 1 / 3 / 2;
  font-family: "Gotham Medium";
}

.membersLI {
  grid-area: 1 / 2 / 3 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.linkedinLogo {
  height: 40px;
  cursor: pointer;
}
