.registerBody {
  display: flex;
  flex-direction: column;
}

.registerTitle {
  color: #0f4c81;
  font-family: "Gotham Bold";
  font-size: 30px;
  text-align: left;
  padding-left: 10%;
  margin-top: 10%;
}

.registerSubtitle {
  color: #0f4c81;
  font-family: "Gotham Bold";
  font-size: 20px;
  text-align: left;
  font-style: italic;
  padding-left: 10%;
}

.registerDisclaimer {
  font-family: "Gotham Bold";
  font-size: 24px;
  color: #969696;
  text-align: center;
  margin-top: 5%;
}

.registerInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.registerInput {
  width: 80%;
  font-family: "Gotham Bold";
  font-size: 20;
  padding-left: 5px;
  color: #393939;
  background-color: #f6f6f6;
  height: 50px;
  border-radius: 15px;
  border: none;
  margin: 10px;
}

.registerCgu {
  color: #0f4c81;
  font-family: "Gotham Book";
  font-style: italic;
  font-size: 15px;
  text-align: right;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.registerButton {
  margin: 0 auto;
  border: none;
  background-color: #0f4c81;
  color: white;
  width: 70%;
  height: 50px;
  border-radius: 5px;
  font-family: "Gotham Bold";
  font-size: 30px;
  cursor: pointer;
}

.registerLinkedIn {
  background: url("../../assets/images/Sign-In-Small---Default.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 292px;
  height: 40px;
  cursor: pointer;
  margin: 2% auto;
}

.registerFusee {
  margin: 10% auto;
  width: 80%;
}
