.profileWrapper {
  font-family: "Gotham Book";
  color: #747474;
}

.langbuttonfr {
  margin: 2.5%;
  text-align: center;
  line-height: 30px;
  color: white;
  font-family: "Gotham Bold";
  width: 100px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  background: #0f4c81;
}

.langbuttonen {
  margin: 2.5%;
  text-align: center;
  line-height: 30px;
  color: white;
  font-family: "Gotham Bold";
  width: 100px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  background: #fc686f;
}

.profileTitle {
  margin: 5% 0 5% 5%;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  margin: 5% 0 0 5%;
  cursor: pointer;
  text-decoration: none;
}

.profileContainerbis {
  display: flex;
  flex-direction: row;
  margin: 5% 0 0 5%;
}

.profileSections {
  padding-top: 2%;
  padding-left: 5%;
}

.profileButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.profileLI {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #0f4c81;
  width: 250px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  color: white;
  cursor: pointer;
}

.liLogo {
  font-family: "Gotham Black";
  margin: 0 5% 0 5%;
}
