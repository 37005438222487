.helpHeader {
  height: 10vh;
  background-image: -moz-linear-gradient(left, #ffffff, #d8d8d8);
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 400;
  color: #0f4c81;
}

.helpTitle {
  position: absolute;
  padding-left: 5%;
  padding-top: 10%;
}

.helpMain {
  width: 100%;
}

.helpAstro {
  margin-top: 35%;
  width: 100%;
}

.helpText {
  margin-top: 40%;
  color: #5f5f5f;
  font-family: "Gotham Book";
  font-size: 12px;
  text-align: center;
}
